<template>
  <CHeader fixed with-subheader light>
    <CHeaderBrand class="ml-3 d-lg-none" to="/">
      <CIcon @click.native="$store.commit('toggleSidebarMobile')" name="cil-hamburger-menu" height="30" alt="menu"/>
    </CHeaderBrand>
    <CHeaderBrand  class="ml-3 mr-auto d-lg-none"> 
      <img class="mr-1" src="../../public/logo-green.svg" width="70" alt="Clica"> <CIcon name="cil-bolt" />
    </CHeaderBrand>
   
    <CHeaderNav class="d-md-down-none mr-auto">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  }
}
</script>

<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0 pb-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <span>Hola, {{user.bot_name}} de {{user.name}} <CIcon :height="20" :content="icons.cilOptions"/></span>

      </CHeaderNavLink>
    </template>
    <!-- <CDropdownItem>
      <CIcon name="cil-settings" /> Configuración
    </CDropdownItem> -->
    <CDropdownItem v-on:click="logout()">
      <CIcon  name="cil-lock-locked" /> Cerrar sesión
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { freeSet } from '@coreui/icons'
const Swal = require('sweetalert2');

export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 42,
      user: this.$store.state.user.data,
      icons: freeSet
    }
  },
  created(){
    
  },
  methods: {
    logout: async function(){
      await Swal.fire({
        title: 'Estás a punto de cerrar sesión',
        html: `¿Estás seguro?`,
        showDenyButton: true,
        confirmButtonText: `Sí`,
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {

          Swal.fire({
            icon: 'success',
            title: 'Cerrando sesión...',
            text: "Te estamos redirigiendo a la pantalla de ingreso",
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            localStorage.setItem('user', null);

            this.$store.dispatch('deleteUser');

            this.$router.push('/login');
          });            
          
        } else if (result.isDenied) {
          
        }
      });
      
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>
export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Leads',
        to: '/leads',
        icon: 'cil-burn',
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Modelos',
        to: '/modelos',
        icon: 'cil-mobile',
        items: [
          {
          name: 'Todos los modelos',
          to: '/modelos/todos',
          icon: 'cil-list-rich',
          },
          {
          name: 'Agregar modelo',
          to: '/modelos/nuevoModelo',
          icon: 'cil-plus',
          },
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Reparaciones',
        to: '/reparaciones',
        icon: 'cil-gem',
        items: [
          {
          name: 'Todas las reparaciones',
          to: '/reparaciones/todos',
          icon: 'cil-list-rich',
          },
          {
          name: 'Agregar reparación',
          to: '/reparaciones/nuevaReparacion',
          icon: 'cil-plus',
          },
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Configuración',
        to: '/config',
        icon: 'cil-settings',
      },
      /*{
        _name: 'CSidebarNavTitle',
        _children: ['Theme']
      }*/
    ]
  }
]